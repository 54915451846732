<template>
  <div class="drop-select" @click.stop="openDropSelect">
    <input
      type="text"
      class="form-control-input"
      :class="activeClass"
      v-model="checkValue"
      autocomplete="off"
      id="drop-select"
      required
      disabled
      @focus="openDropSelect"
    />
    <label v-if="lableShow" class="label-control" for="drop-select"
      >I‘d Like to….
    </label>
    <label
      for="name"
      generated="true"
      class="error"
      v-if="error"
      style="display: inline-block"
      >This field is required.</label
    >
    <div class="list" v-if="dropShow">
      <ul v-if="dropDataList && dropDataList.length > 0">
        <li
          @click.stop="clickItem(item, index)"
          v-for="(item, index) in dropDataList"
          :key="key + index"
          :class="{ border: index < dropDataList.length - 1 }"
        >
          {{ item.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dropDataList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    error: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dropShow: false,
      key: "drop_" + String(Math.random()).slice(-5) + "_",
      checkValue: "",
      activeClass: "",
      lableShow: true
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.checkValue = val.value;
        this.lableShow = !this.checkValue;
        if (!this.checkValue) {
          this.activeClass = "";
        }
      },
      immediate: true
    }
  },
  methods: {
    openDropSelect() {
      this.dropShow = true;
      this.activeClass = "notEmpty";
    },
    clickItem(item, index) {
      this.$emit("input", {
        value: item.value,
        index
      });
      this.activeClass = "notEmpty";
      this.dropShow = false;
    }
  }
};
</script>

<style lang="scss">
.drop-select {
  position: relative;
  z-index: 100;

  .label-control {
    position: absolute;
    left: 0;
    top: 0;
    color: #7d7e80;
    font-size: 16px;
    font-weight: 400;
  }
  .list {
    position: absolute;
    left: 0;
    top: 3rem;
    z-index: 2;
    right: 0;
    background: #f8f8f8;
    ul,
    li {
      list-style: none;
      padding-left: 0;
      box-sizing: border-box;
      margin: 0;
    }
    ul {
      border: 1px solid #4d4b53;
    }
    li {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: space-between;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      height: 3rem;
      align-items: center;
      line-height: 1.1;
      color: rgb(26, 24, 24);
      border: 0;
      &.border {
        border-bottom: 1px solid #9791ae !important;
      }
    }
  }
}
</style>
