<template>
  <section id="contact">
    <section id="fancy-contact-menu">
      <!-- <div class="container"> -->
      <div class="row">
        <!-- COL / LEFT -->
        <div class="col-md-6 left">
          <!-- INFOS -->
          <div class="infos">
            <h2 class="text-big">
              We'd love to<br />
              hear from you
            </h2>

            <!-- HOURS -->
            <div class="hours">
              <div class="row">
                <!-- COL / INNER LEFT -->
                <div class="col-6 col-sm-5 inner-left">
                  <p class="text-medium">Monday - Friday</p>
                </div>
                <!-- END COL / INNER LEFT -->

                <!-- COL / INNER RIGHT -->
                <div class="col-6 col-sm-7 inner-right">
                  <p class="text-medium"><strong>9am to 5pm</strong> PST</p>
                </div>
                <!-- END COL / INNER RIGHT -->
              </div>
              <!-- END ROW -->
            </div>
            <!-- END HOURS -->
          </div>
          <!-- END INFOS -->
        </div>
        <!-- END COL / LEFT -->

        <!-- COL / RIGHT -->
        <div class="col-md-6 right">
          <Contact></Contact>
          <!-- END FORM -->
        </div>
        <!-- END COL / RIGHT -->
      </div>
      <!-- END ROW -->
      <!-- </div> -->
      <!-- END CONTAINER BIG -->
    </section>
    <PrivacyTermsModel></PrivacyTermsModel>
  </section>
</template>
<script>
import Contact from "@/components/Contact";
import PrivacyTermsModel from "@/components/PrivacyTermsModel/PrivacyTermsModel";

export default {
  components: { Contact, PrivacyTermsModel },

  methods: {
    close() {
      this.$router.replace({
        path: "/"
      });
    }
  },
  created() {
    document.title = "Support";
  }
};
</script>

<style lang="scss">
#contact {
  padding: 60px 30px 0;
}
#fancy-contact-menu {
  padding: 40px;
  width: 1200px;
  max-width: calc(100% - 40px);
  margin: 20px auto;
  overflow: hidden;
  z-index: 4;
  // background-color: #fff;
}
.left,
.info {
  position: relative;
}

.text-big {
  font-family: Gotham, sans-serif;
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  color: #222;
}
.left .infos {
  position: relative;
}
@media only screen and (max-width: 1360px) {
  .left .infos {
    padding-left: 0;
  }
}
.left .infos .text-big {
  font-weight: 700;
  color: #222;
}
@media only screen and (max-width: 767px) {
  .left .infos .text-big br {
    display: none;
  }
}
.left .infos .hours {
  display: block;
  margin: 30px 0 35px 0;
}
@media only screen and (max-width: 767px) {
  .left .infos .hours {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 375px) {
  .left .infos .hours {
    margin: 5px 0 15px 0;
  }
}
.left .infos .hours .text-medium {
  color: #555;
  white-space: wrap;
}
@media only screen and (max-width: 575px) {
  .model-body {
    padding: 0 !important;
  }
  .left .infos .hours .text-medium {
    white-space: wrap;
  }
  .text-big {
    font-size: 24px;
  }
  .text-medium {
    font-size: 18px !important;
    line-height: 1.2 !important;
  }
}
@media only screen and (max-width: 375px) {
  .left .infos .hours .text-medium strong {
    padding-top: 10px;
    display: inline-block;
  }
}
.left .infos .email {
  font-weight: 700;
  color: #222;
  position: relative;
}
.left .infos .email:before {
  height: 3px;
  background-color: #222;
}
.left .infos .phone {
  font-weight: 700;
  color: #999;
}
.left .infos .address {
  display: block;
  margin-top: 40px;
  color: #555;
}
@media only screen and (max-width: 767px) {
  .left .infos .address {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.right form {
  position: relative;
  display: block;
  width: 90%;
}
@media only screen and (max-width: 1360px) {
  .right form {
    width: 100%;
  }
}
.right form .input {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #555;
  color: #555;
  padding: 10px 5px;
  margin-bottom: 20px;
  background-color: transparent;
}
@media only screen and (max-width: 767px) {
  .right form .input {
    padding: 5px 2px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .right form .input {
    border-bottom: 1px solid #555;
  }
}
.right form .input.textarea {
  min-height: 130px;
  height: 130px;
  resize: none;
}
@media only screen and (max-width: 767px) {
  .right form .input.textarea {
    min-height: 100px;
    height: 100px;
    resize: vertical;
  }
}
.right form .hollow-black-button {
  margin-top: 10px;
}
#fancy-contact-menu .left .infos .email:before {
  height: 3px;
  background-color: #222;
}
.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098d1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover::before {
  right: 0;
}

@media only screen and (max-width: 1360px) {
  #fancy-contact-menu {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 767px) {
  #fancy-contact-menu {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 575px) {
  #fancy-contact-menu {
    padding: 0px;
    width: 100%;
    display: block;
    margin: 0 !important;
    max-width: 100% !important;
  }
  textarea {
    height: 60px;
  }
}
#fancy-contact-menu .left {
  position: relative;
}
#fancy-contact-menu .left .infos {
  position: relative;
}
#fancy-contact-menu .left .infos .text-big {
  font-weight: 700;
  color: #222;
}

#fancy-contact-menu .left .infos .hours {
  display: block;
  margin: 30px 0 35px 0;
}
@media only screen and (max-width: 767px) {
  #fancy-contact-menu .left .infos .hours {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 375px) {
  #fancy-contact-menu .left .infos .hours {
    margin: 5px 0 15px 0;
  }
}
#fancy-contact-menu .left .infos .hours .text-medium {
  color: #555;
}
@media only screen and (max-width: 375px) {
  #fancy-contact-menu .left .infos .hours .text-medium strong {
    padding-top: 10px;
    display: inline-block;
  }
}
#fancy-contact-menu .left .infos .email {
  font-weight: 700;
  color: #222;
}
#fancy-contact-menu .left .infos .email:before {
  height: 3px;
  background-color: #222;
}
#fancy-contact-menu .left .infos .phone {
  font-weight: 700;
  color: #999;
}
#fancy-contact-menu .left .infos .address {
  display: block;
  margin-top: 40px;
  color: #555;
}
@media only screen and (max-width: 767px) {
  #fancy-contact-menu .left .infos .address {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
#fancy-contact-menu .right form {
  position: relative;
  display: block;
  width: 100%;
}
#fancy-contact-menu .right form .input {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #555;
  color: #555;
  padding: 10px 5px;
  margin-bottom: 20px;
  background-color: transparent;
}
@media only screen and (max-width: 767px) {
  #fancy-contact-menu .right form .input {
    padding: 5px 2px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 375px) {
  #fancy-contact-menu .right form .input {
    border-bottom: 1px solid #555;
  }
}
#fancy-contact-menu .right form .input.textarea {
  min-height: 130px;
  height: 130px;
  resize: none;
}
@media only screen and (max-width: 767px) {
  #fancy-contact-menu .right form .input.textarea {
    min-height: 100px;
    height: 100px;
    resize: vertical;
  }
}
#fancy-contact-menu .right form .hollow-black-button {
  margin-top: 10px;
}
#fancy-contact-menu .right form .hollow-black-button {
  margin-top: 10px;
}
.hollow-black-button {
  display: inline-block;
  line-height: 1;
  padding: 15px 70px;
  -webkit-box-shadow: inset 0 0 0 2px #222;
  box-shadow: inset 0 0 0 2px #222;
  color: #222;
  text-transform: lowercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: transparent;
  font-weight: 700;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: 900;
}
.hollow-black-button:hover {
  background: #000;
  color: #fff;
}
.text-medium {
  font-family: Gotham, sans-serif;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 400;
}

input,
textarea {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #555;
  color: #555;
  padding: 10px 5px !important;
  background-color: transparent;
  outline: none;
}
.input-filed,
.drop-select {
  margin-bottom: 20px;
}
</style>
