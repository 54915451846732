<template>
  <div id="privacypolicy" class="modal fade" :class="activeClass" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            @click="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <PrivacyContent v-if="routeName === 'privacy'"></PrivacyContent>
          <TermsContent v-else></TermsContent>
        </div>
        <div class="modal-footer">
          <button
            @click="close"
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrivacyContent from "./PrivacyContent.vue";
import TermsContent from "./TermsContent.vue";
export default {
  components: {
    PrivacyContent,
    TermsContent
  },

  computed: {
    routeName() {
      return this.$route.name;
    },
    activeClass() {
      return this.routeName === "privacy" || this.routeName === "terms"
        ? "in"
        : "";
    },
    title() {
      return this.routeName === "privacy" ? "Privacy Policy" : "Terms of Use";
    }
  },
  methods: {
    close() {
      this.$router.replace({
        path: "/"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-title {
  text-align: center;
  font-size: 24px;
}
.in {
  display: block;
}
</style>
