import Vue from "vue";
import VueRouter from "vue-router";
import ContactUs from "../views/ContactUs/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Contact",
    component: ContactUs
  },
  {
    path: "/read-privacy",
    name: "privacy",
    component: ContactUs
  },
  {
    path: "/read-terms",
    name: "terms",
    component: ContactUs
  }
];

const routerInstance = () => {
  // write name into path:
  const name = process.env.VUE_APP_NAME;
  const router = new VueRouter({
    mode: "history",
    base: window.__POWERED_BY_QIANKUN__
      ? `/micro/${name}/`
      : process.env.BASE_URL,
    routes
  });

  return router;
};

export default routerInstance;
