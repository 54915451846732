<template>
  <div id="FooterRegion" class="withLangs">
    <footer class="footer clearfix" id="footer" role="contentinfo">
      <div class="footerLinks clearfix textsml" id="footerLinks">
        <ul style="display: flex; justify-content: center; align-items: center">
          <li id="footer-supportcenter">
            ©{{ yearTime }} Fonts Themes Software. All Rights Reserved
          </li>
          <li id="footer-blog">
            <router-link class="footerLink green" to="/read-privacy"
              >Privacy Policy</router-link
            >
          </li>
          <li id="footer-blog">|</li>
          <li id="footer-sitemap">
            <router-link
              to="/read-terms"
              class="footerLink green"
              id="footer-link-sitemap"
              >Terms of Use
            </router-link>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  },
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
.contact-list {
  margin-right: 16px;
}
#FooterRegion {
  background: #222;
  color: #fff;
}
.footer {
  padding: 2em 0;
}
.green {
  color: #1abc9c !important;
}
</style>
